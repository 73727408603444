import { Page } from "./types";

export enum langOptions {
  "en" = "English",
  "fr" = "French",
  "es" = "Spanish",
  "ar" = "Arabic",
  "it" = "Italian",
  "pt" = "Portuguese"
}

export const pageTypes: Page["type"][] = [
  "commitments",
  "legal_info",
  "about_us",
  "about_the_applications",
  "payments",
];
export const pieChartOptions = {
  labels: ["Accepted Missions", "Canceled Missions"],
  colors: ["#4318FF", "#6AD2FF"],
  chart: {
    width: "50px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: false,
  },
  hover: { mode: "" },
  plotOptions: {
    donut: {
      expandOnClick: false,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: ["#4318FF", "#6AD2FF"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
  dataLabels: {
    enabled: true,
    formatter: (val: number) => val + "%",
  },
};
export const pageTypesLabels: { [key in Page["type"]]: string } = {
  commitments: "Commitments",
  legal_info: "Legal Info",
  about_us: "About us",
  about_the_applications: "About the applications",
  payments: "Payments",
};

export enum REPORT_STATUS {
  PENDING = "PENDING",
  DISMISSED = "DISMISSED",
  VALIDATED = "VALIDATED",
}

export enum PURCHASE_STATUS {
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  PROCESSED = "PROCESSED",
}
